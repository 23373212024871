@font-face {
  font-family: my-font-bold;
  src: url("./fonts/Montserrat-Bold.woff2");
}
@font-face {
  font-family: my-font-light;
  src: url("./fonts/Montserrat-Light.woff2");
}
@font-face {
  font-family: my-font-regular;
  src: url("./fonts/Montserrat-Regular.woff2");
}
@font-face {
  font-family: my-font-thin;
  src: url("./fonts/Montserrat-ExtraLight.woff2");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: my-font-light, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  scrollbar-width: thin;
  scrollbar-color: #838383 #2c2c2c;
}

body {
  background-color: #141010;
  color: white;
}

*::selection {
  color: #f50505;
  background-color: transparent;
}

::-webkit-scrollbar {
  background-color: #2c2c2c;
  width: 7.5px;
}

::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 50px;
  width: 10px;
}

scrol code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  padding: 1.5rem 4rem;
  min-width: 150px;
  cursor: pointer;
  font-family: my-font-bold;
  border-radius: 31px;
  outline: none;
  font-size: 14px;
  border: 0;
  background-color: #460d0d;
  color: white;
}

button.active {
  background-color: #f50505;
  pointer-events: none;
}

button:active {
  appearance: none;
}

button#nav-button {
  padding: 1.2rem 3rem;
  background-color: transparent;
  border: 2px solid #f50505;
  color: #f50505;
}
a {
  font-size: 1.6rem;
  display: inline-block;
  font-family: my-font-light;
  text-decoration: none;
  padding-right: 3.5rem;
  color: white;
}

a.active {
  color: #f50505;

  /* font-family: my-font-bold; */
}

span {
  font-family: my-font-bold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

h4 {
  font-size: 2.4rem;
}

ul {
  list-style-type: none;
}
